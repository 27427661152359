// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Redirect logo to main site
$(".mainLogo").prop("href", "https://blind.org.uk/");

// Replace Gift Aid text

//$('.donationGiftAid label.checkboxRadio').first().html('<strong>Boost your donation by 25p of Gift Aid for every £1 you donate.</strong><br /><br />Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. Your address is needed to identify you as a current UK taxpayer. In order to Gift Aid your donation you must tick the box below. ');
  
$('.formSecondStep.giftAidStatement').first().html('<strong>I am a UK tax payer and would like to Gift Aid my donation and any donations I make in future or have made in the past four years.</strong><br /><br />I understand if I pay less income tax and/or capital gains tax than the amount of Gift Aid claimed on all my donations in that tax year, it is my responsibility to pay any difference. Please notify BWBF if you want to cancel this declaration, change your name or home address or no longer pay sufficient tax on your income and/or capital gains. If you pay income tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your tax code.');
  